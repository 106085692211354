import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import antLocaleAz from './constants/antLocaleAz';
import * as serviceWorker from './serviceWorker';
import 'moment/locale/az';

import Router from './containers/Router';

import store from './core/configs/store';
import './less/main.less';
import './scss/main.scss';
import './core/utils/flat/flat';
import moment from 'moment';

moment.locale('az');

const root: JSX.Element = (
  <Provider store={store}>
    <ConfigProvider locale={antLocaleAz}>
      <Router />
    </ConfigProvider>
  </Provider>
);

const container: Element | null = document.querySelector('#root');

ReactDOM.render(root, container);

serviceWorker.unregister();
