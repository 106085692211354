import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { ITableActions } from '../interfaces/table/actions.interface';
import { IAppState } from '../interfaces/app-state.interface';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { TableActionTypes } from '../constants/table';
import { tableFetchDataFailedAction, tableFetchDataStartedAction, tableFetchDataSucceedAction } from '../actions/table';
import { caller, urlFactory } from '../core/utils/caller';
import { FormDataFlat } from '../core/utils/flat';
import { PathMaker } from '../core/utils/path-maker';
import { tableFilterParser } from '../core/helpers/table-filter-parser';
import { v4 as uuid } from 'uuid';

const fetchData = async ({ source = 'base', ...params }: any) => {
  switch (source) {
    case 'bonaz':
      return fetchBonazConversionsData(params);
    default:
      return fetchBaseData(params);
  }
};

const fetchBaseData = async ({ name, apiUrl, tableState }: any) => {
  const filters = tableFilterParser(tableState.filters);

  const sort = tableState.sortBy.length
    ? {
        sort_column: tableState.sortBy[0].id,
        sort_order: tableState.sortBy[0].desc ? 'desc' : 'asc',
      }
    : {};

  const page = {
    page: tableState.pageIndex + 1,
    per_page: tableState.pageSize,
  };

  const flat = new FormDataFlat({ ...filters, ...sort, ...page }, new PathMaker('square-bracket'));

  const response = await caller(urlFactory(apiUrl, flat.values()));

  if (response.ok) {
    const { data, total, meta } = await response.json();

    return tableFetchDataSucceedAction(name, {
      total: total || data.length,
      data,
      meta,
    });
  } else {
    throw new Error('Unexpected error');
  }
};

const fetchBonazConversionsData = async ({ name, apiUrl, tableState }: any) => {
  const filters = tableFilterParser(tableState.filters);

  const sort = tableState.sortBy.length
    ? {
        sort_column: tableState.sortBy[0].id,
        sort_order: tableState.sortBy[0].desc ? 'desc' : 'asc',
      }
    : {};

  const page = {
    start: tableState.pageIndex * tableState.pageSize,
    limit: tableState.pageSize,
  };

  const flat = new FormDataFlat({ ...filters, ...sort, ...page }, new PathMaker('square-bracket'));

  const response = await caller(urlFactory(apiUrl, flat.values()));

  if (response.ok) {
    const { result, total, meta } = await response.json();

    return tableFetchDataSucceedAction(name, {
      total,
      data: result.map((data) => ({ id: uuid(), ...data })),
      meta,
    });
  } else {
    throw new Error('Unexpected error');
  }
};

export const tableFetchDataEpic: Epic<ITableActions, ITableActions, IAppState> = (action$: ActionsObservable<ITableActions>, state: StateObservable<IAppState>): Observable<any> => {
  return action$.pipe(
    filter(isOfType(TableActionTypes.FETCH_DATA)),
    mergeMap(({ name, apiUrl, source }) => {
      return concat(
        of(tableFetchDataStartedAction(name)),
        from(fetchData({ name, apiUrl, tableState: state.value.table[name], source })).pipe(catchError(() => of(tableFetchDataFailedAction(name, 'Məlumatlar əldə edilə bilmədi')))),
      );
    }),
  );
};
