import { useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { search2Object } from '../../core/utils/search-to-object';

export const useClose = (next: string) => {
  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => search2Object(location.search), [location.search]);

  return useCallback(
    (additionalParams?: any) => {
      const url = new URL(params.next || next, window.location.origin);
      if (additionalParams && !additionalParams.target) {
        Object.entries(additionalParams).forEach(([key, value]: any) => {
          if (value) {
            url.searchParams.append(key, value.toString());
          }
        });
      }
      history.push(url.pathname + url.search);
    },
    [params.next, next, history],
  );
};
