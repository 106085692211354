import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Loading } from './Loading';

const ProtectedRoute: FC<RouteProps & { authed: boolean | undefined }> = ({ authed, ...rest }) => {
  if (authed === true) {
    return <Route {...rest} />;
  } else if (authed === false) {
    return <Redirect to='/login' />;
  } else {
    return <Loading />;
  }
};

export default ProtectedRoute;
