import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { DropboxOutlined, ReadOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd/es/menu';
import { usePermissions } from '../../../hooks/permissions/usePermissions';

export const SidebarMenu = React.memo<MenuProps>((props) => {
  const location = useLocation();
  const { can } = usePermissions();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }, [location]);

  return (
    <Menu id='mainSidebarMenu' selectedKeys={selectedKeys} theme='dark' mode='inline' {...props}>
      {can('backoffice_couriers') && (
        <Menu.Item key='/couriers' icon={<DropboxOutlined />}>
          Kuryerlər
          <NavLink to='/couriers' />
        </Menu.Item>
      )}
      {can('backoffice_news') && (
        <Menu.Item key='/news' icon={<ReadOutlined />}>
          Xəbərlər
          <NavLink to='/news' />
        </Menu.Item>
      )}
    </Menu>
  );
});
