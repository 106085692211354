import { INewsFetch, INewsFetchFailedAction, INewsFetchStartedAction, INewsFetchSucceedAction } from '../types/actions';
import { NewsActionTypes } from '../constants/news';
import { INews } from '../types/model';
import { Dispatch } from 'redux';
import { message } from 'antd';
import { caller, urlFactory } from '../../../../core/utils/caller';
import { formDataBuilder } from '../../../../core/utils/form-data-builder';
import { UnexpectedError } from '../../../../core/helpers/errors';

export const newsFetchAction = (): INewsFetch => ({
  type: NewsActionTypes.FETCH,
});

export const newsFetchStartedAction = (): INewsFetchStartedAction => ({
  type: NewsActionTypes.FETCH_STARTED,
});

export const newsFetchSucceedAction = (payload: INews[]): INewsFetchSucceedAction => ({
  type: NewsActionTypes.FETCH_SUCCEED,
  payload,
});

export const newsFetchFailedAction = (payload?: string): INewsFetchFailedAction => ({
  type: NewsActionTypes.FETCH_FAILED,
  payload,
});

export const removeNewsAction = (objectId: number[]) => async (dispatch: Dispatch) => {
  const messageKey = 'news-remove-message';
  const values = { news_id: objectId };

  const onSuccess = (success) => {
    dispatch(newsFetchAction());
    return success;
  };

  const onError = (error) => {
    message.error({ content: 'Xəbər(lər) silinə bilmədi', key: messageKey });
    return error;
  };

  message.loading({ content: 'Əməliyyat aparılır', key: messageKey });
  try {
    const url = urlFactory('/api/backoffice/news/cancel');
    const body = formDataBuilder({ values });

    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};
