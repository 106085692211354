import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, Upload } from 'antd';
import { WrappedFieldProps } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { UploadProps } from 'antd/lib/upload';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons/lib';

const Simple = React.memo<WrappedFieldProps & { item: FormItemProps; field: UploadProps }>(({ input, item, field, meta: { touched, error, warning } }) => {
  const errorText = useMemo(() => touched && error, [touched, error]);
  const warningText = useMemo(() => touched && warning, [touched, warning]);
  const validateStatus = useMemo(() => {
    if (errorText) {
      return 'error';
    } else if (warningText) {
      return 'warning';
    }
  }, [errorText, warningText]);

  const uploadProps = useMemo(
    () => ({
      name: 'image',
      multiple: false,
      onChange: ({ file }) => file.status !== 'removed' && input.onChange(file),
      onRemove: () => input.onChange(null),
      beforeUpload: () => false,
      fileList: input.value ? [input.value] : [],
      ...field,
    }),
    [input, field],
  );

  return (
    <Form.Item {...item} validateStatus={validateStatus} help={errorText || warningText}>
      <Upload {...uploadProps}>
        <Button type='primary' ghost={true}>
          <UploadOutlined /> Sənəd yüklə
        </Button>
      </Upload>
    </Form.Item>
  );
});

const Multi = React.memo<WrappedFieldProps & { item: FormItemProps; field: UploadProps; placeholder?: string; mini?: boolean }>(
  ({ input, item, field, placeholder, mini, meta: { touched, error, warning } }) => {
    const errorText = useMemo(() => touched && error, [touched, error]);
    const warningText = useMemo(() => touched && warning, [touched, warning]);
    const validateStatus = useMemo(() => {
      if (errorText) {
        return 'error';
      } else if (warningText) {
        return 'warning';
      }
    }, [errorText, warningText]);
    const preventEffect = useRef<boolean>(false);

    const [fileList, setFileList] = useState([]);

    const handleChange = useCallback(
      ({ fileList }) => {
        preventEffect.current = true;
        setFileList(fileList);
        input.onChange(fileList.map(({ originFileObj }) => originFileObj));
      },
      [input],
    );

    const handleRemove = useCallback(
      ({ uid }) => {
        preventEffect.current = true;
        input.onChange(input.value?.filter(({ inputUID }) => inputUID !== uid));
        setFileList((state) => state.filter(({ listUID }) => listUID !== uid));
      },
      [input],
    );

    useEffect(() => {
      if (!preventEffect.current) {
        setFileList([]);
      }
      preventEffect.current = false;
    }, [input.value]);

    const uploadProps = useMemo(
      () => ({
        name: 'file_upload',
        multiple: true,
        onChange: handleChange as any,
        onRemove: handleRemove,
        beforeUpload: () => false,
        fileList,
        ...field,
      }),
      [field, handleChange, fileList, handleRemove],
    );

    return (
      <Form.Item {...item} validateStatus={validateStatus} help={errorText || warningText}>
        <Upload.Dragger {...uploadProps}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          {!mini && <p className='ant-upload-text'>{placeholder || 'Sənədləri yüklə'}</p>}
          <p className='ant-upload-hint'>Sənədləri yükləmək üçün klikləyib sənədləri seçə və ya sürüşdürə bilərsiniz.</p>
        </Upload.Dragger>
      </Form.Item>
    );
  },
);

export const UploadField = { Simple, Multi };
