import flatten from 'flat';

export const toSearch = (object: Record<string, any>): string => {
  const flattened = flatten(object);
  const arrayedObject = Object.entries(flattened);
  return arrayedObject.length
    ? arrayedObject.reduce((acc, [key, value], index) => {
        return acc + (index ? '&' : '?') + `${key}=${value}`;
      }, '')
    : '';
};
