export class TypeChecker {
  public static isBoolean(value: any) {
    return typeof value === 'boolean';
  }

  public static isString(value: any) {
    return typeof value === 'string';
  }

  public static isSymbol(value: any) {
    return typeof value === 'symbol';
  }

  public static isNumber(value: any) {
    return typeof value === 'number';
  }

  public static isArray(value: any) {
    return Array.isArray(value);
  }

  public static isBlob(value: any) {
    return value instanceof Blob;
  }

  public static isFile(value: any) {
    return value instanceof File;
  }

  public static isObject(value: any) {
    return typeof value === 'object';
  }
}
