import React from 'react';
import bike from './../../assets/images/bike.gif';

export const Loading = () => {
  return (
    <div className='loading-wrapper'>
      <img src={bike} alt='Findex loading animation' />
    </div>
  );
};
