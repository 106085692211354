import React, { useMemo } from 'react';
import { Form, Input } from 'antd';
import { WrappedFieldProps } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { InputProps } from 'antd/lib/input';

export const InputField = React.memo<
  WrappedFieldProps & { item: FormItemProps; field: InputProps }
>(({ input, item, field, meta: { touched, error, warning } }) => {
  const errorText = useMemo(() => touched && error, [touched, error]);
  const warningText = useMemo(() => touched && warning, [touched, warning]);
  const validateStatus = useMemo(() => {
    if (errorText) {
      return 'error';
    } else if (warningText) {
      return 'warning';
    }
  }, [errorText, warningText]);

  return (
    <Form.Item
      {...item}
      validateStatus={validateStatus}
      help={errorText || warningText}
    >
      <Input {...input} {...field} />
    </Form.Item>
  );
});

export const PasswordField = React.memo<
  WrappedFieldProps & { item: FormItemProps; field: InputProps }
>(({ input, item, field, meta: { touched, error, warning } }) => {
  const errorText = useMemo(() => touched && error, [touched, error]);
  const warningText = useMemo(() => touched && warning, [touched, warning]);
  const validateStatus = useMemo(() => {
    if (errorText) {
      return 'error';
    } else if (warningText) {
      return 'warning';
    }
  }, [errorText, warningText]);

  return (
    <Form.Item
      validateStatus={validateStatus}
      help={errorText || warningText}
      {...item}
    >
      <Input.Password {...input} {...field} />
    </Form.Item>
  );
});
