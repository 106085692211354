import { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { UseQueryOptions } from '../types';

export const useQuery = <T = any>(query, { initialData, enabled = true, fetchOnce, initialLoading = true }: UseQueryOptions = {}) => {
  const handleFetchRef = useRef<any>(() => {});
  const fetchedOnceRef = useRef<boolean>(false);
  const dataRef = useRef<T>(initialData);

  const [data, setData] = useState<T>(initialData);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(enabled ? initialLoading : false);

  const handleFetch = useCallback(
    async (disableLoading?: boolean) => {
      disableLoading || setLoading(true);
      try {
        const data = await query({ setError });
        setData(data);
      } catch (e) {
        setError(e.message);
      } finally {
        disableLoading || setLoading(false);
      }
    },
    [query],
  );

  const reset = useCallback(() => {
    setData(initialData);
  }, [initialData]);

  useEffect(() => {
    (async () => {
      if (enabled && !(fetchOnce && fetchedOnceRef.current)) {
        fetchedOnceRef.current = true;
        await handleFetch();
      }
    })();
  }, [enabled, handleFetch, fetchOnce]);

  useImperativeHandle(handleFetchRef, () => handleFetch, [handleFetch]);
  useImperativeHandle(dataRef, () => data, [data]);

  return { data, loading, error, reset, reFetchRef: handleFetchRef, setData };
};
