import React, { FC } from 'react';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import classNames from 'classnames';

const ContentCard: FC<CardProps> = ({ className, ...props }) => {
  return <Card className={classNames('content-card', className)} {...props} />;
};

export default React.memo(ContentCard);
