import { Redirect, Route, Switch } from 'react-router-dom';
import { RequestCourierHandingPage } from '../pages/request-handing';
import { PreviewCourierHandingPage } from '../pages/preview-handing';

export const CouriersRouter = () => {
  return (
    <Route path='/couriers'>
      <Switch>
        <Route path='/couriers/handing/:id/preview' component={PreviewCourierHandingPage} />
        <Route path='/couriers/handing/request' component={RequestCourierHandingPage} />
        <Redirect to='/couriers/handing/request' />
      </Switch>
    </Route>
  );
};
