export const makePath = (path: string, params: Record<string, any>, search?: Record<string, any>) => {
  let newPath = path;

  Object.entries(params).forEach(([key, value]) => {
    newPath = newPath.replace(`:${key}`, value);
  });

  if (search) {
    Object.entries(search)
      .filter(([_, value]) => value)
      .forEach(([key, value], index) => {
        newPath += index ? `&${key}=${value}` : `?${key}=${value}`;
      });
  }

  return newPath;
};
