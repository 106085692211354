import { useSelector } from 'react-redux';
import { permissionsSelector } from '../../selectors/common';
import { useCallback } from 'react';
import { IAppState } from '../../interfaces/app-state.interface';

export const usePermissions = () => {
  const permissions = useSelector<IAppState, string[]>(permissionsSelector());

  const can = useCallback(
    (role) => {
      return permissions.includes(role);
    },
    [permissions],
  );

  return { can, permissions };
};
