import { catchError, filter, mergeMap } from 'rxjs/operators';
import { ActionsObservable, Epic } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { concat, from, Observable, of } from 'rxjs';
import { IAuthActions } from '../interfaces/auth/actions';
import { IAppState } from '../interfaces/app-state.interface';
import { userResponseParser } from '../core/parsers/user-response.parser';
import { AuthActionTypes } from '../constants/auth';
import { caller, urlFactory } from '../core/utils/caller';
import { authFetchFailedAction, authFetchStartedAction, authFetchSucceedAction } from '../actions/auth';
import history from '../core/configs/history';

const onFetchAuth = async ({ redirect = false }) => {
  const response = await caller(urlFactory('/api/client/user'));

  if (response.ok) {
    const data = await response.json();
    if (redirect) {
      history.push('/');
    }
    return authFetchSucceedAction(userResponseParser(data));
  } else {
    throw new Error('Məlumatlar əldə edilə bilmədi');
  }
};

export const authEpic: Epic<IAuthActions, IAuthActions, IAppState> = (action$: ActionsObservable<IAuthActions>): Observable<IAuthActions> => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.FETCH)),
    mergeMap(({ payload }) => {
      return concat(
        of(authFetchStartedAction()),
        from(onFetchAuth({ redirect: payload.redirect })).pipe(
          catchError((e) => {
            return of(authFetchFailedAction(e.message));
          }),
        ),
      );
    }),
  );
};
