import React, { FC, useCallback, useMemo } from 'react';
import { Form, Modal, Spin } from 'antd';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { RouteComponentProps } from 'react-router-dom';

import { useClose } from '../../../../hooks/common';
import routes from '../../../../constants/routes';
import { useNewsFormFields } from '../hooks/use-form';
import { urlFactory } from '../../../../core/utils/caller';
import { useForm } from '../../../../hooks/common/useForm';
import { Dispatch } from 'redux';
import { shopsFetchAction } from '../../../../actions/shops';

type IProps = InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch };

const NewsForm: FC<IProps> = ({ submitting, handleSubmit: baseHandleSubmit, initialize, initialized, match: { params }, dispatch }) => {
  const handleClose = useClose(routes.news.index);

  const handleSubmitSuccess = useCallback(() => {
    dispatch(shopsFetchAction());
  }, [dispatch]);

  const formOptions = useMemo(
    () => ({
      handleClose,
      handleSubmit: baseHandleSubmit,
      initialize,
      createUrl: routes.news.create,
      createApiUrl: urlFactory('/api/backoffice/news/create'),
      editApiUrl: urlFactory('/api/backoffice/news/edit', { news_id: params.id }),
      initializeUrl: urlFactory('/api/client/news/info', { id: params.id }),
      initializeMiddlewares: [(values) => ({ ...values, image: undefined })],
      onSuccess: handleSubmitSuccess,
    }),
    [params.id, handleSubmitSuccess, baseHandleSubmit, initialize, handleClose],
  );

  const { handleSubmit, type } = useForm(formOptions);

  const { titleFieldProps, bodyFieldProps, imageFieldProps, descriptionsFieldProps } = useNewsFormFields();

  const title = useMemo(() => (type === 'create' ? 'Yeni xəbər əlavə et' : 'Xəbərdə düzəliş et'), [type]);

  return (
    <Modal maskTransitionName='' transitionName='' width={576} visible={true} onCancel={handleClose} confirmLoading={submitting} onOk={handleSubmit} title={title}>
      <Spin spinning={type === 'edit' && !initialized}>
        <Form layout='vertical'>
          <Field {...titleFieldProps} />
          <Field {...descriptionsFieldProps} />
          <Field {...imageFieldProps} />
          <Field {...bodyFieldProps} />
        </Form>
      </Spin>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'news' })(React.memo<IProps>(NewsForm));
