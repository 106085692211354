import { CourierPrint, CourierPrintApi } from '../types/print';

export class CouriersPrintMapper {
  public static oneFromApi(courier: CourierPrintApi): CourierPrint {
    return {
      id: courier.id,
      client: {
        id: courier.user_id,
        name: courier.user_name,
      },
      address: courier.address,
      phone: courier.phone,
      region: {
        id: 0,
        name: courier.region_name,
      },
      recipient: courier.recipient,
      declarations: courier.declarations.map((declaration): CourierPrint['declarations'][0] => ({
        id: declaration.id,
        trackCode: declaration.track_code,
        quantity: declaration.quantity,
        weight: parseFloat(declaration.weight).toFixed(2),
        prices: {
          product: (typeof declaration.price === 'string' ? parseFloat(declaration.price) : declaration.price).toFixed(2),
          delivery: (typeof declaration.delivery_price === 'string' ? parseFloat(declaration.delivery_price) : declaration.delivery_price).toFixed(2),
          penalty: (typeof declaration.penalty_price === 'string' ? parseFloat(declaration.penalty_price) : declaration.penalty_price).toFixed(2),
        },
      })),
      prices: {
        courier: (typeof courier.price === 'string' ? parseFloat(courier.price) : courier.price).toFixed(2),
        penalty: (typeof courier.penalty === 'string' ? parseFloat(courier.penalty) : courier.penalty).toFixed(2),
        total: (typeof courier.all_amount === 'string' ? parseFloat(courier.all_amount) : courier.all_amount).toFixed(2),
      },
    };
  }
}
