import { FormDataFlat } from './flat';
import { objectMutator } from './object-mutator/object-mutator';
import { selectOnly } from './object-mutator/select-only';
import { selectExpect } from './object-mutator/select-expect';
import { IFormDataBuilderOptions } from '../../interfaces/core/utils/form-data-builder.interface';

export const formDataBuilder = ({
  values,
  only,
  expect,
}: IFormDataBuilderOptions): FormData => {
  const formData: FormData = new FormData();
  const flat: FormDataFlat = new FormDataFlat(values);
  const flattened: Record<string, any> = flat.values();

  const mutated = objectMutator<Record<string, any>>(flattened, [
    (object: Record<string, any>) => (only ? selectOnly(object, only) : object),
    (object: Record<string, any>) =>
      expect ? selectExpect(object, expect) : object,
  ]);

  for (const key in mutated) {
    if (mutated.hasOwnProperty(key)) {
      formData.append(key, mutated[key]);
    }
  }

  return formData;
};
