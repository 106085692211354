export enum TableActionTypes {
  INITIALIZE = '@table/initialize',
  DESTROY = '@table/destroy',
  RESET = '@table/reset',
  SET_FILTERS = '@table/set/filters',
  SET_FILTERS_BY_ID = '@table/set/filters/byId',
  SET_SORT_BY = '@table/set/sortBy',
  SET_PAGE_INDEX = '@table/set/pageIndex',
  SET_PAGE_SIZE = '@table/set/pageSize',
  SET_SELECTED_ROW_IDS = '@table/set/selectedRowIds',
  SET_HIDDEN_COLUMNS = '@table/set/hiddenColumns',
  SELECT_ALL = '@table/set/selectAll',
  FETCH_DATA = '@table/fetch/data',
  FETCH_DATA_STARTED = '@table/fetch/data/started',
  FETCH_DATA_SUCCEED = '@table/fetch/data/succeed',
  FETCH_DATA_FAILED = '@table/fetch/data/failed',
}
