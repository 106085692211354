import { useCallback, useEffect } from 'react';
import { message } from 'antd';

import { caller } from '../../../core/utils/caller';
import { UnexpectedError } from '../../../core/helpers/errors';

export const useInitialize = ({ url, onSuccess, onError, disabled, middlewares }: any) => {
  const handleInitialize = useCallback(async () => {
    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        const mutatedData = middlewares
          ? middlewares.reduce((acc, middleware) => {
              return middleware(acc);
            }, data)
          : data;
        if (onSuccess) {
          onSuccess(mutatedData);
        }
      } else {
        UnexpectedError();
      }
    } catch (e) {
      if (onError) {
        onError();
      } else {
        message.error('Məlumatlar əldə edilə bilmədi');
      }
    }
  }, [url, onError, onSuccess, middlewares]);

  useEffect(() => {
    if (!disabled) {
      handleInitialize().then();
    }
  }, [disabled]); //eslint-disable-line react-hooks/exhaustive-deps

  return handleInitialize;
};
