import { createSelector } from 'reselect';
import { IAppState } from '../interfaces/app-state.interface';
import { IThemeState } from '../interfaces/theme/model.interface';
import { IAuthState } from '../interfaces/auth/state';

export const layoutSelector = createSelector<IAppState, IThemeState, IAuthState, any>(
  (state: IAppState) => {
    return state.theme as IThemeState;
  },
  (state: IAppState) => {
    return state.auth as IAuthState;
  },
  (theme: IThemeState, auth: IAuthState) => {
    return {
      theme,
      auth,
    };
  },
);
