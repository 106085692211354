import { isOfType } from 'typesafe-actions';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { ActionsObservable, Epic } from 'redux-observable';

import { caller, urlFactory } from '../../../../core/utils/caller';
import { newsFetchFailedAction, newsFetchStartedAction, newsFetchSucceedAction } from '../actions/news';
import { IAppState } from '../../../../interfaces/app-state.interface';
import { NewsActionTypes } from '../constants/news';
import { INewsActions } from '../types/actions';

const fetchData = async () => {
  const response = await caller(urlFactory('/api/client/news'));

  if (response.ok) {
    const { data } = await response.json();

    return newsFetchSucceedAction(data);
  } else {
    throw new Error('Unexpected error');
  }
};

export const newsFetchDataEpic: Epic<INewsActions, INewsActions, IAppState> = (action$: ActionsObservable<INewsActions>): Observable<any> => {
  return action$.pipe(
    filter(isOfType(NewsActionTypes.FETCH)),
    switchMap(() => {
      return concat(of(newsFetchStartedAction()), from(fetchData()).pipe(catchError(() => of(newsFetchFailedAction('Məlumatlar əldə edilə bilmədi')))));
    }),
  );
};
