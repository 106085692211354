import React, { useMemo } from 'react';

import { InputField } from '../../../../../components/ReduxForm/Input';
import { TextAreaField } from '../../../../../components/ReduxForm/TextArea';
import { UploadField } from '../../../../../components/ReduxForm/Upload';
import { Typography } from 'antd';

export const useNewsFormFields = () => {
  const titleFieldProps = useMemo(
    () => ({
      name: 'title',
      item: { label: 'Başlıq' },
      field: { placeholder: 'Başlıq daxil edin' },
      component: InputField,
    }),
    [],
  );

  const bodyFieldProps = useMemo(
    () => ({
      name: 'body',
      item: { label: 'Link' },
      component: InputField,
    }),
    [],
  );

  const imageFieldProps = useMemo(
    () => ({
      name: 'image',
      component: UploadField.Simple,
      item: {
        label: (
          <>
            Xəbərin təsviri&nbsp;
            <Typography.Paragraph style={{ marginBottom: 0 }} type='secondary'>
              (Tövsiyyə olunan ölçü nisbəti: 1x1)
            </Typography.Paragraph>
          </>
        ),
      },
    }),
    [],
  );

  const descriptionsFieldProps = useMemo(
    () => ({
      name: 'descr',
      item: { label: 'Açıqlama' },
      field: { placeholder: 'Açıqlama daxil edin', rows: 3 },
      component: TextAreaField,
    }),
    [],
  );

  return useMemo(
    () => ({
      titleFieldProps,
      bodyFieldProps,
      descriptionsFieldProps,
      imageFieldProps,
    }),
    [titleFieldProps, bodyFieldProps, descriptionsFieldProps, imageFieldProps],
  );
};
