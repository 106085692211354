import { IAuthFetch, IAuthFetchFailedAction, IAuthFetchStartedAction, IAuthFetchSucceedAction, IAuthLogoutAction } from '../interfaces/auth/actions';
import { AuthActionTypes } from '../constants/auth';
import { IAuthStateData } from '../interfaces/auth/state';
import { Dispatch } from 'redux';
import { caller, urlFactory } from '../core/utils/caller';

export const authFetchAction = (payload: Record<string, any>): IAuthFetch => ({
  type: AuthActionTypes.FETCH,
  payload,
});

export const authFetchStartedAction = (): IAuthFetchStartedAction => ({
  type: AuthActionTypes.FETCH_STARTED,
});

export const authFetchSucceedAction = (payload: IAuthStateData): IAuthFetchSucceedAction => ({
  type: AuthActionTypes.FETCH_SUCCEED,
  payload,
});

export const authFetchFailedAction = (payload?: string): IAuthFetchFailedAction => ({
  type: AuthActionTypes.FETCH_FAILED,
  payload,
});

export const authLogoutAction = (): IAuthLogoutAction => ({
  type: AuthActionTypes.LOGOUT,
});

export const handleLogoutAction = () => async (dispatch: Dispatch) => {
  const url = urlFactory('/api/backoffice/logout');

  try {
    await caller(url);
    dispatch(authLogoutAction());
  } catch (e) {}
};
