export class PathMaker {
  constructor(private connector: 'square-bracket' | 'dot' = 'square-bracket') {}

  public make(path: string, key: string): string {
    switch (this.connector) {
      case 'dot':
        return path.length ? `${path}.${key}` : key;
      default:
        return path.length ? `${path}[${key}]` : key;
    }
  }

  public getConnector(): string {
    return this.connector;
  }

  public setConnector(connector: 'square-bracket' | 'dot'): void {
    this.connector = connector;
  }
}
