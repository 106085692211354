import React, { FC } from 'react';
import { Alert } from 'antd';
import { AlertProps } from 'antd/lib/alert';

const FormError: FC<AlertProps> = (props) => {
  return props.message ? (
    <Alert
      {...props}
      type='error'
      className='form-error-alert'
      showIcon={true}
      closable={true}
    />
  ) : null;
};

export default FormError;
