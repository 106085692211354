import { TypeChecker } from '../type-checker';
import { PathMaker } from '../path-maker';

export class Flat {
  protected target: Record<string, any> = {};

  public constructor(private readonly source: Record<string, any>, protected readonly pathMaker: PathMaker = new PathMaker()) {
    this.make();
  }

  protected step(key: string, value: any, path: string) {
    const newPath = this.pathMaker.make(path, key);

    if (TypeChecker.isBlob(value)) {
      this.target[newPath] = value;
    } else if (TypeChecker.isFile(value)) {
      this.target[newPath] = value;
    } else if (TypeChecker.isArray(value)) {
      (value as any[]).forEach((item: any, index) => {
        this.step(index.toString(), item, newPath);
      });
    } else if (TypeChecker.isObject(value)) {
      this.make(newPath, value);
    } else {
      this.target[newPath] = value;
    }
  }

  protected make(path: string = '', object?: Record<string, any>) {
    const source = object || this.source;

    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        const value = source[key];
        this.step(key, value, path);
      }
    }
  }

  public values() {
    return this.target;
  }
}
