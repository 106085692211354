import { FormDataFlat } from './flat';
import { PathMaker } from './path-maker';
import { ICallerInit } from '../../interfaces/core/utils/caller.interface';
import Cookie from 'js-cookie';
import { object2Search } from './object-to-search';

export const caller = async (input: RequestInfo, init: ICallerInit = { auth: true }): Promise<Response> => {
  let accessToken: string | undefined = Cookie.get('access_token');

  const additionalHeaders: HeadersInit = {
    Accept: 'application/json',
    Authorization: accessToken ? `Bearer ${accessToken}` : '',
  };

  const finalInit: RequestInit = {
    ...init,
    headers: {
      ...(init ? init.headers : {}),
      ...additionalHeaders,
    },
  };

  return fetch(input, finalInit);
};

export const urlFactory = (pathname: string, params: Record<string, any> = {}) => {
  const flattenedParams = new FormDataFlat(params, new PathMaker('square-bracket'));
  const stringParams = object2Search(flattenedParams.values());
  const { search: _search = '' } = new URL(`${process.env.REACT_APP_API_HOST}${pathname}`);
  const search = stringParams + (_search.length ? _search.slice(1) : '');
  const hasHost = pathname.includes('http');

  return `${!hasHost ? process.env.REACT_APP_API_HOST : ''}${pathname}${search ? `${search}` : ''}`;
};
