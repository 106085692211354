import { produce } from 'immer';
import { IShopsState } from '../interfaces/shops/state';
import { IShopsActions } from '../interfaces/shops/actions';
import { ShopsActionTypes } from '../constants/shops';

const initialState: IShopsState = {
  loading: false,
  data: [],
};

export default produce(
  (state: IShopsState = initialState, action: IShopsActions): IShopsState => {
    switch (action.type) {
      case ShopsActionTypes.FETCH_STARTED:
        state.error = undefined;
        state.loading = true;
        break;
      case ShopsActionTypes.FETCH_SUCCEED:
        state.loading = false;
        state.data = action.payload;
        break;
      case ShopsActionTypes.FETCH_FAILED:
        state.loading = false;
        state.error = action.payload;
        break;
    }

    return state;
  },
);
