import {
  ITableDestroyAction,
  ITableFetchDataAction,
  ITableFetchDataFailedAction,
  ITableFetchDataStartedAction,
  ITableFetchDataSucceedAction,
  ITableInitializeAction,
  ITableResetAction,
  ITableSelectAllAction,
  ITableSetFiltersAction,
  ITableSetFiltersByIdAction,
  ITableSetHiddenColumnsAction,
  ITableSetPageIndexAction,
  ITableSetPageSizeAction,
  ITableSetSelectedRowIdsAction,
  ITableSetSortByAction,
} from '../interfaces/table/actions.interface';
import { TableActionTypes } from '../constants/table';

export const tableInitializeAction = (name: string): ITableInitializeAction => ({
  type: TableActionTypes.INITIALIZE,
  name,
});

export const tableDestroyAction = (name: string): ITableDestroyAction => ({
  type: TableActionTypes.DESTROY,
  name,
});

export const tableResetAction = (name: string): ITableResetAction => ({
  type: TableActionTypes.RESET,
  name,
});

export const tableSetFiltersAction = (name: string, filters: any[]): ITableSetFiltersAction => ({
  type: TableActionTypes.SET_FILTERS,
  name,
  filters,
});

export const tableSetFiltersByIdAction = (name: string, id: string, value: any): ITableSetFiltersByIdAction => ({
  type: TableActionTypes.SET_FILTERS_BY_ID,
  name,
  id,
  value,
});

export const tableSetSortByAction = (name: string, sortBy: any[]): ITableSetSortByAction => ({
  type: TableActionTypes.SET_SORT_BY,
  name,
  sortBy,
});

export const tableSetPageIndexAction = (name: string, pageIndex: number): ITableSetPageIndexAction => ({
  type: TableActionTypes.SET_PAGE_INDEX,
  name,
  pageIndex,
});

export const tableSetPageSizeAction = (name: string, pageSize: number): ITableSetPageSizeAction => ({
  type: TableActionTypes.SET_PAGE_SIZE,
  name,
  pageSize,
});

export const tableSetHiddenColumns = (name: string, hiddenColumns: string[]): ITableSetHiddenColumnsAction => ({
  type: TableActionTypes.SET_HIDDEN_COLUMNS,
  name,
  hiddenColumns,
});

export const tableSetSelectedRowIdsAction = (name: string, selectedRowIds: Record<string | number, true>): ITableSetSelectedRowIdsAction => ({
  type: TableActionTypes.SET_SELECTED_ROW_IDS,
  name,
  selectedRowIds,
});

export const tableSelectAllAction = (name: string): ITableSelectAllAction => ({
  type: TableActionTypes.SELECT_ALL,
  name,
});

export const tableFetchDataAction = (name: string, apiUrl: string, source: string = 'base'): ITableFetchDataAction => ({
  type: TableActionTypes.FETCH_DATA,
  name,
  apiUrl,
  source,
});

export const tableFetchDataStartedAction = (name: string): ITableFetchDataStartedAction => ({
  type: TableActionTypes.FETCH_DATA_STARTED,
  name,
});

export const tableFetchDataSucceedAction = (name: string, { total, data, meta }: { total: number; data: any[]; meta: any }): ITableFetchDataSucceedAction => ({
  type: TableActionTypes.FETCH_DATA_SUCCEED,
  name,
  total,
  data,
  meta,
});

export const tableFetchDataFailedAction = (name: string, error?: string | undefined): ITableFetchDataFailedAction => ({
  type: TableActionTypes.FETCH_DATA_FAILED,
  name,
  error,
});
