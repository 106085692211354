import { Draft, produce } from 'immer';
import { ITableReducer, ITableState } from '../interfaces/table/model.interface';
import { TableActionTypes } from '../constants/table';

const initialPageSize = parseInt(localStorage.getItem('tablePageSize') || '50', 10);

export const initialTableState: ITableState = {
  pageIndex: 0,
  pageSize: !isNaN(initialPageSize) && initialPageSize <= 100 ? initialPageSize : 50,
  sortBy: [],
  filters: [],
  hiddenColumns: [],
  selectedRowIds: {},
  total: 0,
  loading: false,
  error: undefined,
  data: [],
  meta: undefined,
};

const initialState: ITableReducer = {};

export default produce((state: Draft<ITableReducer> = initialState, action: any) => {
  switch (action.type) {
    case TableActionTypes.INITIALIZE:
      if (!state[action.name]) {
        state[action.name] = {} as any;
      }

      if (!state[action.name]?.sortBy) {
        state[action.name].sortBy = initialTableState.sortBy;
      }

      if (!state[action.name]?.filters) {
        state[action.name].filters = initialTableState.filters;
      }

      if (!state[action.name]?.selectedRowIds) {
        state[action.name].selectedRowIds = initialTableState.selectedRowIds;
      }

      if (!state[action.name]?.pageIndex) {
        state[action.name].pageIndex = initialTableState.pageIndex;
      }

      if (!state[action.name]?.pageSize) {
        state[action.name].pageSize = initialTableState.pageSize;
      }

      if (!state[action.name]?.hiddenColumns) {
        state[action.name].hiddenColumns = initialTableState.hiddenColumns;
      }

      if (!state[action.name]?.total) {
        state[action.name].total = initialTableState.total;
      }

      if (!state[action.name]?.loading) {
        state[action.name].loading = initialTableState.loading;
      }

      if (state[action.name]?.error) {
        state[action.name].error = initialTableState.error;
      }

      if (state[action.name]) {
        state[action.name].data = initialTableState.data;
        state[action.name].initialized = true;
      }

      break;
    case TableActionTypes.RESET:
      if (state[action.name]) {
        state[action.name].selectedRowIds = initialTableState.selectedRowIds;
        state[action.name].sortBy = initialTableState.sortBy;
        state[action.name].filters = initialTableState.filters;
        state[action.name].pageIndex = initialTableState.pageIndex;
        state[action.name].pageSize = initialTableState.pageSize;
      }
      break;
    case TableActionTypes.DESTROY:
      if (state[action.name]) {
        state[action.name].initialized = false;
      }
      break;
    case TableActionTypes.SET_FILTERS:
      if (!state[action.name]) {
        state[action.name] = {} as any;
      }
      state[action.name].filters = action.filters;
      break;
    case TableActionTypes.SET_FILTERS_BY_ID:
      if (state[action.name]) {
        const tableFilters = state[action.name].filters;
        const foundFilterIndex = tableFilters.findIndex((filter) => filter.id === action.id);
        if (foundFilterIndex !== -1) {
          tableFilters[foundFilterIndex].value = action.value;
        } else {
          tableFilters.push({ id: action.id, value: action.value });
        }
      }
      break;
    case TableActionTypes.SET_SORT_BY:
      if (state[action.name]) {
        state[action.name].sortBy = action.sortBy;
      }
      break;
    case TableActionTypes.SET_PAGE_INDEX:
      if (state[action.name]) {
        state[action.name].pageIndex = action.pageIndex;
      }
      break;
    case TableActionTypes.SET_PAGE_SIZE:
      if (state[action.name]) {
        state[action.name].pageSize = action.pageSize;
      }
      break;
    case TableActionTypes.SET_HIDDEN_COLUMNS:
      if (state[action.name]) {
        state[action.name].hiddenColumns = action.hiddenColumns;
      }
      break;
    case TableActionTypes.SET_SELECTED_ROW_IDS:
      if (state[action.name]) {
        state[action.name].selectedRowIds = action.selectedRowIds;
      }
      break;
    case TableActionTypes.FETCH_DATA_STARTED:
      if (state[action.name]) {
        state[action.name].loading = true;
        state[action.name].error = undefined;
      }
      break;
    case TableActionTypes.FETCH_DATA_SUCCEED:
      if (state[action.name]) {
        state[action.name].loading = false;
        state[action.name].data = action.data;
        state[action.name].total = action.total;
        state[action.name].meta = action.meta;
      }
      break;
    case TableActionTypes.FETCH_DATA_FAILED:
      if (state[action.name]) {
        state[action.name].loading = false;
        state[action.name].error = action.error;
        state[action.name].data = [];
      }
      break;
    case TableActionTypes.SELECT_ALL:
      if (state[action.name]) {
        state[action.name].selectedRowIds =
          state[action.name].data
            ?.map(({ id }) => id)
            .reduce(
              (acc, val) => ({
                ...acc,
                [val]: true,
              }),
              {},
            ) || {};
      }
      break;
  }

  return state;
});
