import Cookies from 'js-cookie';

export const caller = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  let accessToken: string | null = (await Cookies.get('access_token')) || null;

  const additionalHeaders: HeadersInit = {
    Accept: 'application/json',
    Authorization: accessToken ? `Bearer ${accessToken}` : '',
  };

  const finalInit: RequestInit = {
    ...init,
    headers: {
      ...(init ? init.headers : {}),
      ...additionalHeaders,
    },
  };

  return fetch(input, finalInit);
};
