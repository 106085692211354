import { useCallback } from 'react';
import { message } from 'antd';
import { CouriersService } from '../services';
import { handlebars } from 'hbs';

//@ts-ignore
import handingTemplate from '../hbs/handing.hbs';

export const useCourier = (id: string | number) => {
  const handOver = useCallback(async () => {
    message.loading({ key: id, content: 'Əməliyyat aparılır...', duration: null });

    const result = await CouriersService.handOver([id]);

    if (result.status === 200) {
      message.success({ key: id, content: 'Təhvil verildi...' });
    } else {
      message.error({ key: id, content: result.data });
    }
  }, [id]);

  const printHanding = useCallback(async () => {
    message.loading({ key: id, content: 'Handing hazırlanır...', duration: null });

    const result = await CouriersService.Print.getById(id);

    if (result.status === 200) {
      const template = handlebars.compile(await fetch(handingTemplate).then((r) => r.text()));
      const readyTemplate = template([result.data]);
      message.destroy(id);

      const printWindow = window.open();

      if (printWindow) {
        printWindow.document.write(readyTemplate);
        printWindow.document.close();
      }
    } else {
      message.error({ content: 'Kuryer handing hazırlana bilmədi', key: id });
    }
  }, [id]);

  const getHandingHTML = useCallback(async (): Promise<string | null> => {
    const result = await CouriersService.Print.getById(id);

    if (result.status === 200) {
      const template = handlebars.compile(await fetch(handingTemplate).then((r) => r.text()));
      return template([result.data]);
    } else {
      return null;
    }
  }, [id]);

  return { handOver, printHanding, getHandingHTML };
};
