import { IShopsFetch, IShopsFetchFailedAction, IShopsFetchStartedAction, IShopsFetchSucceedAction } from '../interfaces/shops/actions';
import { ShopsActionTypes } from '../constants/shops';
import { ICategory } from '../interfaces/shops/model';

export const shopsFetchAction = (): IShopsFetch => ({
  type: ShopsActionTypes.FETCH,
});

export const shopsFetchStartedAction = (): IShopsFetchStartedAction => ({
  type: ShopsActionTypes.FETCH_STARTED,
});

export const shopsFetchSucceedAction = (payload: ICategory[]): IShopsFetchSucceedAction => ({
  type: ShopsActionTypes.FETCH_SUCCEED,
  payload,
});

export const shopsFetchFailedAction = (payload?: string): IShopsFetchFailedAction => ({
  type: ShopsActionTypes.FETCH_FAILED,
  payload,
});
