import { IUser } from '../../interfaces/users/model';

export const userResponseParser = (payload: any): IUser => {
  return {
    id: payload.data.id,
    firstName: payload.data.name,
    lastName: payload.data.surname,
    email: payload.data.email,
    permissions: payload.permissions.map(({ code_name }) => code_name),
  };
};
