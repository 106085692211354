import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { Result } from '../../../shared/utils/result';
import { CouriersMapper } from '../mappers';
import { CourierPrint } from '../types/print';

export class CouriersPrintService {
  public static async getById(id: string | number): Promise<Result<200, CourierPrint> | Result<400 | 500, string>> {
    const url = urlMaker('/api/backoffice/couriers/info', { courier_id: id, print: 1 });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        const mappedData = CouriersMapper.Print.oneFromApi(data);

        return new Result(200, mappedData, null);
      } else {
        return new Result(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new Result(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
}
