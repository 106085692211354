import { Flat } from './flat';
import { TypeChecker } from '../type-checker';

export class FormDataFlat extends Flat {
  protected step(key: string, value: any, path: string) {
    const newPath = this.pathMaker.make(path, key);

    if (TypeChecker.isBoolean(value)) {
      this.target[newPath] = Number(value).toString();
    } else if (TypeChecker.isNumber(value)) {
      this.target[newPath] = value.toString();
    } else if (TypeChecker.isString(value)) {
      this.target[newPath] = value;
    } else if (TypeChecker.isBlob(value)) {
      this.target[newPath] = value;
    } else if (TypeChecker.isFile(value)) {
      this.target[newPath] = value;
    } else if (TypeChecker.isArray(value)) {
      (value as any[]).forEach((item: any, index) => {
        this.step(index.toString(), item, newPath);
      });
    } else if (value !== null && TypeChecker.isObject(value)) {
      this.make(newPath, value);
    }
  }
}
