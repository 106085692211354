import { produce } from 'immer';
import { IAuthState } from '../interfaces/auth/state';
import { IAuthActions } from '../interfaces/auth/actions';
import { AuthActionTypes } from '../constants/auth';

const initialState: IAuthState = {
  loading: false,
  authed: undefined,
};

export default produce(
  (state: IAuthState = initialState, action: IAuthActions): IAuthState => {
    switch (action.type) {
      case AuthActionTypes.FETCH_STARTED:
        state.error = undefined;
        state.authed = undefined;
        state.loading = true;
        break;
      case AuthActionTypes.FETCH_SUCCEED:
        state.loading = false;
        state.authed = true;
        state.data = action.payload;
        break;
      case AuthActionTypes.FETCH_FAILED:
        state.loading = false;
        state.authed = false;
        state.error = action.payload;
        break;
      case AuthActionTypes.LOGOUT:
        state.authed = false;
        state.error = undefined;
        state.data = undefined;
        break;
    }

    return state;
  },
);
