import { Draft, produce } from 'immer';
import { IThemeState } from '../interfaces/theme/model.interface';
import { IThemeActions } from '../interfaces/theme/actions.interface';
import { ThemeActionTypes } from '../constants/theme';

const initialState: IThemeState = {
  collapsed: false,
};

export default produce(
  (state: Draft<IThemeState> = initialState, action: IThemeActions): IThemeState => {
    switch (action.type) {
      case ThemeActionTypes.TOGGLE_COLLAPSE:
        state.collapsed = action.payload || !state.collapsed;
        break;
      default:
        break;
    }
    return state;
  },
);
