export const search2Object = (search: string) => {
  const urlSearchParams = new URLSearchParams(search);
  const result: Record<string, string | null> = {};

  urlSearchParams.forEach((value, key) => {
    result[key] = value;
  });

  return result;
};
