import { SubmissionError } from 'redux-form';
import { message } from 'antd';

export const UnexpectedError = () => {
  throw new Error('Unexpected error');
};

export const ValidationError = (errors) => {
  throw new SubmissionError(errors);
};

export const ValidationMessage = (errors: Record<string, string[]>) => {
  Object.values(errors).forEach((error) => {
    message.error(error[0]);
  });
};
