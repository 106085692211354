import React, { useCallback } from 'react';
import { Layout } from 'antd';
import { SiderProps } from 'antd/lib/layout';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCollapseAction } from '../../../actions/theme';
import { baseModelSelector } from '../../../selectors/common';
import { IThemeState } from '../../../interfaces/theme/model.interface';

const { Sider } = Layout;

export type LayoutSidebarProps = {
  sider?: SiderProps;
  header?: JSX.Element;
  menu?: JSX.Element;
};

export const LayoutSidebar = React.memo<LayoutSidebarProps>(({ sider, menu, header }) => {
  const dispatch = useDispatch();

  const theme = useSelector(baseModelSelector<IThemeState>('theme'));

  const handleCollapse = useCallback(
    (collapsed: boolean) => {
      dispatch(toggleCollapseAction(collapsed));
    },
    [dispatch],
  );

  return (
    <Sider collapsible={true} width={224} collapsedWidth={48} collapsed={theme.collapsed} onCollapse={handleCollapse} id='mainSidebar' breakpoint='lg' {...sider}>
      {header}
      {menu}
    </Sider>
  );
});
