import { regexIsMatch } from '../regex-is-match';

export const selectExpect = (
  object: Record<string, any>,
  properties: string[],
): Record<string, any> => {
  return Object.entries(object).reduce(
    (acc: Record<string, any>, [key, value]: [string, any]) => {
      const matched = regexIsMatch(key, properties);

      if (!matched) {
        return { ...acc, [key]: value };
      } else {
        return acc;
      }
    },
    {},
  );
};
