import React, { useCallback, useMemo } from 'react';
import { Button, Card, Form, Input } from 'antd';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { localURLMaker } from '../../../shared/utils/url-maker';

export const RequestCourierHandingPage = () => {
  type FormData = { id: string };
  const history = useHistory();

  const initialValues = useMemo<FormData>(() => ({ id: '' }), []);
  const onSubmit = useCallback(
    async (values: FormData) => {
      history.push(localURLMaker('/couriers/handing/:id/preview', { id: values.id }));
    },
    [history],
  );

  const { handleSubmit, handleChange, values } = useFormik<FormData>({ initialValues, onSubmit });

  return (
    <Card title='Kuryer handing' size='small'>
      <Form>
        <Input name='id' onChange={handleChange} value={values.id} style={{ marginBottom: 12 }} placeholder='Kuryerin izləmə kodunu daxil edin...' />
        <Button block={true} type='primary' onClick={() => handleSubmit()}>
          Çap et
        </Button>
      </Form>
    </Card>
  );
};
