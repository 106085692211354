import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, message, Space } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import * as Icons from '@ant-design/icons';

import ContentCard from '../../../../containers/Layout/Sidebar/Content/Card';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { useCourier } from '../hooks/use-courier';

export const PreviewCourierHandingPage = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { handOver, getHandingHTML, printHanding } = useCourier(params.id);

  const { data, loading, error } = useQuery<string | null>(
    useCallback(async () => {
      const result = await getHandingHTML();

      if (!result) {
        throw new Error('Kuryer tapılmadı');
      }

      return result;
    }, [getHandingHTML]),
    useMemo(() => ({ initialData: null }), []),
  );

  useEffect(() => {
    if (error) {
      message.error(error);
      history.push('/couriers/handing/request');
    }
  }, [error, history]);

  const frameStyle = useMemo(() => ({ width: '100%', flex: 1 }), []);

  return (
    <ContentCard
      loading={loading}
      extra={
        <Space size={8}>
          <Button type='link' size='small' icon={<Icons.PrinterOutlined />} onClick={printHanding}>
            Çap et
          </Button>
          <Button type='link' size='small' icon={<Icons.CheckOutlined />} onClick={handOver}>
            Təhvil ver
          </Button>
        </Space>
      }
      title={`#${params.id} kuryer handing`}
      size='small'
    >
      <iframe id='courier-handing-preview' name='courier-handing-preview' title='courier-handing-preview' sandbox='allow-same-origin' srcDoc={data || ''} style={frameStyle} frameBorder={0} />
    </ContentCard>
  );
};
