import { CouriersPrintService } from './print';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { Result } from '../../../shared/utils/result';

export class CouriersService {
  public static Print = CouriersPrintService;

  public static async handOver(ids: (number | string)[]): Promise<Result<200> | Result<400 | 500, string>> {
    const url = urlMaker('/api/backoffice/couriers/edit/state', { courier_id: ids });

    try {
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        return new Result(200, null, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        const mappedErrors = Object.values<string[]>(errors).flat().join('. ');

        return new Result(400, mappedErrors, null);
      } else {
        return new Result(400, 'Əməliyyat aparıla bilmədi', null);
      }
    } catch (e) {
      return new Result(400, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
}
