import React, { FC, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Router as BaseRouter, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MainLayout from './Layout';
import LoginPage from '../pages/Login';

import routes from '../constants/routes';

import NewsForm from '../next/modules/news/containers/form';
import News from '../next/modules/news/pages/news';

import ProtectedRoute from '../components/Common/ProtectedRoute';
import history from '../core/configs/history';

import { usePermissions } from '../hooks/permissions/usePermissions';
import { authFetchAction } from '../actions/auth';
import { baseModelSelector } from '../selectors/common';

import { IAuthState } from '../interfaces/auth/state';
import { CouriersRouter } from '../next/modules/couriers/router';

const Router: FC = () => {
  const dispatch = useDispatch();
  const auth = useSelector(baseModelSelector<IAuthState>('auth'));
  const { can } = usePermissions();

  useEffect(() => {
    dispatch(authFetchAction({}));
  }, [dispatch]);

  return (
    <BrowserRouter>
      <BaseRouter history={history}>
        <React.Suspense fallback={() => 'Loading...'}>
          <Switch>
            <Route path={routes.login.index} component={LoginPage} />
            <ProtectedRoute authed={auth.authed} path={routes.home.index}>
              <MainLayout>
                <Switch>
                  {can('backoffice_news') && (
                    <Route path={routes.news.index}>
                      <Route path={routes.news.index} component={News} />
                      <Route path={[routes.news.create, routes.news.edit]} component={NewsForm} />
                    </Route>
                  )}
                  {can('backoffice_couriers') && <Route path='/couriers' component={CouriersRouter} />}
                  {can('backoffice_news') && <Redirect to='/news' />}
                  {can('backoffice_couriers') && <Redirect to='/couriers' />}
                </Switch>
              </MainLayout>
            </ProtectedRoute>
          </Switch>
        </React.Suspense>
      </BaseRouter>
    </BrowserRouter>
  );
};

export default React.memo(Router);
