import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import auth from './auth';
import table from './table';
import shops from './shops';
import news from '../next/modules/news/reducers/news';
import theme from './theme';

const reducers = { auth, table, shops, news, theme, form };

export default combineReducers(reducers);
